import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils';

export function reloadFieldData() {
}

export default [
  {
    name: 'modelTag',
    label: '型號標識',
    type: 'text',
    rule: 'required|max:30',
    edit_disable: true,
  },
  {
    name: 'purposes',
    label: '類型',
    type: 'checkboxFlow',
    rule: 'required',
    options: kioskUtils.purposes,
    edit_disable: true,
  },
  {
    name: 'name',
    label: '名稱',
    type: 'text',
    rule: 'max:30',
  },
  {
    name: 'brand',
    label: '品牌',
    type: 'text',
    rule: 'max:30',
  },
  {
    name: 'model',
    label: '型號',
    type: 'text',
    rule: 'max:30',
  },
  {
    name: 'remark',
    label: '型號備註',
    type: 'text',
    rule: 'max:200',
  },
]
