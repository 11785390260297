import common from '@/common'

export default {
  getKioskEnvOptions() {
    return common.apiGetData('/kioskmanage/GetKioskEnvOptions')
      .then(res => {
        const environments = res.data.environments ?? res.data.environements ?? []
        const [envOptions, platformOptions] = [[], []]
        environments.forEach(env => {
          envOptions.push({
            text: env,
            value: env,
          })
        })
        res.data.platforms.forEach(platform => {
          platformOptions.push({
            text: platform,
            value: platform,
          })
        })
        return { envOptions, platformOptions }
      })
  },
  purposes: [
    {
      value: 'NfcReader',
      text: common.getI18n('kiosk.nfc_reader')
    },
    {
      value: 'BodyTemp',
      text: common.getI18n('kiosk.temperature')
    },
    {
      value: 'BP',
      text: common.getI18n('kiosk.blood_pressure')
    },
    {
      value: 'BO',
      text: common.getI18n('kiosk.oximetry')
    },
    {
      value: 'Weight',
      text: common.getI18n('kiosk.weight')
    },
    {
      value: 'Height',
      text: common.getI18n('kiosk.height')
    },
    {
      value: 'Glucose',
      text: common.getI18n('kiosk.blood_glucose')
    },
    {
      value: 'GripStrength',
      text: common.getI18n('kiosk.grip_strength')
    },
  ]
}
